import { FC } from 'react';
import { ExternalLink } from 'lucide-react';
import {
  GetCapellaUrlQuery,
  GetCapellaUrlResponse,
} from '../../services/graphql/capellaUrl';
import { useQuery } from '@apollo/client';
import { generateFullCapellaURL } from '../CapellaIntegration/generateFullCapellaURL';
import { TestID } from '../../constants/testIds';

export const CapellaSchedulerButton: FC = () => {
  const { data } = useQuery<GetCapellaUrlResponse>(GetCapellaUrlQuery);

  const schedulerURL = generateFullCapellaURL(
    'Scheduler',
    data?.credentials?.capellaConfig?.url ?? ''
  );

  const handleClick = () => {
    window.open(schedulerURL, '_blank');
  };

  return (
    <div
      className='font-500 mr-2 flex cursor-pointer items-center rounded-md bg-blue-100 px-4 py-2 text-sm text-[#0078D8] hover:opacity-80'
      onClick={handleClick}
      data-testid={TestID.Calendar.OpenCapellaSchedulerButton}
    >
      Open Capella Scheduler
      <ExternalLink size={16} className='mb-0.5 ml-2' />
    </div>
  );
};
