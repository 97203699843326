import { FC } from 'react';
import { ExternalLink } from 'lucide-react';
import {
  GetCapellaUrlQuery,
  GetCapellaUrlResponse,
} from '../../services/graphql/capellaUrl';
import { useQuery } from '@apollo/client';
import { generateFullCapellaURL } from '../CapellaIntegration/generateFullCapellaURL';
import { TestID } from '../../constants/testIds';

interface CapellaMyQButtonProps {
  className?: string;
}

export const CapellaMyQButton: FC<CapellaMyQButtonProps> = ({ className }) => {
  const { data } = useQuery<GetCapellaUrlResponse>(GetCapellaUrlQuery);

  const myQURL = generateFullCapellaURL(
    'MyQ',
    data?.credentials?.capellaConfig?.url ?? ''
  );

  const handleClick = () => {
    window.open(myQURL, '_blank');
  };

  return (
    <div
      className={`font-500 flex cursor-pointer items-center rounded-md border px-4 py-2 text-xs text-[#94a3b8] hover:opacity-60 ${className}`}
      onClick={handleClick}
      data-testid={TestID.Header.CapellaMyQButton}
    >
      Capella MyQ
      <ExternalLink size={16} className='mb-0.5 ml-2' />
    </div>
  );
};
