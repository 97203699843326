import { gql } from '@apollo/client';
import { DateRange } from '../../types/dateType';

export type TranscriptionListVariables = {
  pageSize: number;
  pageNumber: number;
  statuses?: string[] | null;
  practitionerIds?: string[] | null;
  appointmentDate?: DateRange | null;
  submissionDate?: DateRange | null;
  searchQuery?: string;
};

export const TranscriptionList = gql`
  query TranscriptionList(
    $pageSize: Int
    $pageNumber: Int
    $statuses: [String]
    $practitionerIds: [String]
    $appointmentDate: DateRange
    $submissionDate: DateRange
    $searchQuery: String
  ) {
    transcriptionsList(
      pageSize: $pageSize
      pageNumber: $pageNumber
      filter: {
        statuses: $statuses
        practitionerIds: $practitionerIds
        dateOfEncounter: $appointmentDate
        dateOfSubmission: $submissionDate
      }
      searchQuery: $searchQuery
    ) {
      totalCount
      transcriptions {
        transcriptionId
        status
        source
        dateSubmitted
        scribeNote {
          scribeNoteId
          note
        }
        encounter {
          patient {
            patientId
            givenName
            familyName
            birthDate
            age
            sex
            phone
            address
            region
            coverage
            memberId
          }
          practitioner {
            practitionerId
            givenName
            familyName
            oktaId
          }
          dateOfEncounter
          encounterId
          note
          noteTitle
          resourceType
        }
      }
    }
  }
`;
